<template>
  <EditorContent v-if="editor" :editor="editor" class="tiptap-editable" />
</template>

<script>
import { EditorContent, useEditor } from '@tiptap/vue-3'
import Paragraph from '@tiptap/extension-paragraph'
import StarterKit from '@tiptap/starter-kit'
import HardBreak from '@tiptap/extension-hard-break'
import debounce from 'debounce'

import { onBeforeUnmount, onMounted, watch, inject } from 'vue'

export default {
  name: 'ContentEditor',
  props: {
    content: {
      type: String,
      required: true
    },
    onUpdate: {
      type: Function,
      required: true
    },
    forcedTag: {
      type: String,
      default: null
    }
  },
  components: {
    EditorContent
  },
  setup(props, { emit }) {
    const currentEditor = inject('currentEditor')

    const debouncedUpdate = debounce((html) => {
      props.onUpdate(html)
    }, 1000)

    const generateContent = (content, forcedTag) => {
      return forcedTag ? `<${forcedTag}>${content}</${forcedTag}>` : content
    }

    const editor = useEditor({
      extensions: [
        StarterKit
      ],
      content: generateContent(props.content, props.forcedTag),
      onUpdate: ({ editor }) => {
        debouncedUpdate(editor.getHTML())
      }
    })

    onMounted(() => {
      emit('editor-created', editor.value)
    })

    onBeforeUnmount(() => {
      editor.value.destroy()
    })

    return { editor }
  }
}
</script>
