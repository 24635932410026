import { computed } from 'vue'

const CONSTANTS = {
  YOUTUBE_SHORTS: {
    MAX_WIDTH: '320px',
    HEIGHT: '690px',
  },
  TIKTOK: {
    MAX_WIDTH: '320px',
    HEIGHT: '550px',
  },
  OTHER_EMBED: {
    WIDTH: '640px',
    HEIGHT: '360px',
  },
  OVERLAY_CLIP_PATH: {
    VERTICAL: 'polygon(0% 0%, 0% 94%, 40% 94%, 40% 46%, 60% 46%, 60% 60%, 25% 60%, 25% 94%, 100% 94%, 100% 0%)',
    HORIZONTAL: 'polygon(0% 0%, 0% 87%, 46% 87%, 46% 42%, 54% 42%, 54% 54%, 25% 54%, 25% 87%, 100% 87%, 100% 0%)',
  },
}

export function useEmbedHelpers(embed) {
  const isYouTube = computed(() => embed.value.provider === 'youtube')
  const isYouTubeShorts = computed(() => embed.value.provider === 'youtube_shorts')
  const isTikTok = computed(() => embed.value.provider === 'tiktok')
  const isOtherEmbed = computed(() => embed.value.provider === 'other')

  const youtubeWrapperClass = computed(() => {
    return embed.value.embeddable === 'Block'
      ? 'relative w-full h-full flex justify-center overflow-hidden px-10'
      : 'relative w-full rounded-lg border-2 '
  })

  const youtubeStyle = computed(() => {
    return embed.value.embeddable === 'Block'
      ? { aspectRatio: embed.value.aspect_ratio }
      : { paddingBottom: embed.value.aspect_ratio_percent + '%' }
  })

  const youtubeVideoClass = computed(() => {
    return embed.value.embeddable === 'Block'
      ? 'w-full h-full rounded-lg'
      : 'absolute top-0 left-0 w-full h-full border-0 rounded-lg'
  })

  const overlayWidth = computed(() => {
    if (isYouTubeShorts.value || isTikTok.value) return CONSTANTS.YOUTUBE_SHORTS.MAX_WIDTH
    if (isOtherEmbed.value) return CONSTANTS.OTHER_EMBED.WIDTH
    return '100%'
  })

  const overlayHeight = computed(() => {
    if (isYouTubeShorts.value) return CONSTANTS.YOUTUBE_SHORTS.HEIGHT
    if (isTikTok.value) return CONSTANTS.TIKTOK.HEIGHT
    if (isOtherEmbed.value) return CONSTANTS.OTHER_EMBED.HEIGHT
    return '100%'
  })

  const overlayClipPath = computed(() => {
    if (isYouTubeShorts.value || isTikTok.value)
      return CONSTANTS.OVERLAY_CLIP_PATH.VERTICAL
    if (isOtherEmbed.value || isYouTube.value)
      return CONSTANTS.OVERLAY_CLIP_PATH.HORIZONTAL
    return 'none'
  })

  return {
    CONSTANTS,
    isYouTube,
    isYouTubeShorts,
    isTikTok,
    isOtherEmbed,
    youtubeWrapperClass,
    youtubeStyle,
    youtubeVideoClass,
    overlayWidth,
    overlayHeight,
    overlayClipPath,
  }
}
