<template>
  <div :id="`player-${block.id}`" class="h-full">
    <div
      :class="`flex items-center max-w-[${CONSTANTS.YOUTUBE_SHORTS.MAX_WIDTH}] h-[${CONSTANTS.YOUTUBE_SHORTS.HEIGHT}] overflow-hidden rounded-lg aspect-[9/16] w-fit mx-auto`"
      data-provider="youtube_shorts"
    >
      <div data-youtube-target="video"></div>
    </div>
  </div>
</template>

<script>
import { toRef, watch, onMounted } from 'vue'
import { useEmbedHelpers } from '../../composables/useEmbedHelpers'
import { useYouTubePlayer } from '../../composables/useYouTubePlayer'

export default {
  name: 'YouTubeShorts',
  props: {
    block: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const embedRef = toRef(props.block, 'embed')
    const { CONSTANTS } = useEmbedHelpers(embedRef)
    const { initYouTubePlayer } = useYouTubePlayer(props.block.id)

    const getVideoId = () => {
      return props.block.embed.external_embed_id || new URL(props.block.embed.original_url).pathname.split('/').pop()
    }

    onMounted(() => {
      if (window.YT) {
        initYouTubePlayer(getVideoId())
      } else {
        console.error('YouTube API not loaded')
      }
    })

    watch(
      () => props.block.embed.original_url,
      () => {
        initYouTubePlayer(getVideoId())
      }
    )

    return {
      CONSTANTS
    }
  }
}
</script>
