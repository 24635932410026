<template>
  <div
    class="h-full bg-white shadow-lg ease-in-out transition-all duration-300 side-panel relative"
    :class="[block ? 'w-[384px]' : 'w-0']"
  >
    <div v-if="block" class="p-4 relative">
      <button @click="closePanel" class="absolute top-0 right-0 text-gray-500 hover:text-gray-700 p-4">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <h2 class="text-lg font-semibold mb-4">Video settings</h2>
      <VideoForm :block="block" />
    </div>
  </div>
</template>

<script>
import VideoForm from '../embeds/VideoForm.vue'

export default {
  name: 'SidePanel',
  components: {
    VideoForm
  },
  props: {
    block: {
      type: Object,
      default: null
    }
  },
  methods: {
    closePanel() {
      this.$emit('close')
    }
  }
}
</script>
