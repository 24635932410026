<template>
  <div :id="`player-${block.id}`" class="h-full">
    <div
      :class="youtubeWrapperClass"
      data-provider="youtube"
      :style="youtubeStyle">
      <div :class="youtubeVideoClass" data-youtube-target="video"></div>
    </div>
  </div>
</template>

<script>
import { toRef, onMounted, watch, ref } from 'vue'
import { useEmbedHelpers } from '../../composables/useEmbedHelpers'
import { useYouTubePlayer } from '../../composables/useYouTubePlayer'

export default {
  name: 'YouTubeEmbed',
  props: {
    block: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const embedRef = toRef(props.block, 'embed')
    const { initYouTubePlayer } = useYouTubePlayer(props.block.id)

    const { youtubeWrapperClass, youtubeStyle, youtubeVideoClass } = useEmbedHelpers(embedRef)

    const getVideoId = () => {
      return props.block.embed.external_embed_id || new URL(props.block.embed.original_url).searchParams.get('v')
    }

    onMounted(() => {
      if (window.YT) {
        initYouTubePlayer(getVideoId(), props.block.embed.start, props.block.embed.end)
      } else {
        console.error('YouTube API not loaded')
      }
    })

    watch(
      () => [props.block.embed.original_url, props.block.embed.start, props.block.embed.end],
      () => {
        initYouTubePlayer(getVideoId(), props.block.embed.start, props.block.embed.end)
      }
    )

    return {
      youtubeWrapperClass,
      youtubeStyle,
      youtubeVideoClass,
    }
  }
}
</script>
