import { inject, ref } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { useBlockUpdate } from './useBlockUpdate'

export function useBlockEditor(block) {
  const { updateBlock } = useBlockUpdate()
  const currentEditor = inject('currentEditor')
  const slides = inject('parsedSlides')
  const blockRef = ref(null)

  const onUpdatedBlock = (content, field) => {
    const slideId = slides.value.find(slide => slide.blocks.some(b => b.id === block.id))?.id

    const formData = new FormData()
    formData.append('slide_id', slideId)
    formData.append(`block[${field}]`, content)

    updateBlock(block.update_path, formData)
  }

  onClickOutside(blockRef, (event) => {
    if (currentEditor.value === block.editor || !block.editor) {
      const toolbar = document.querySelector('.tiptap-toolbar')

      if (currentEditor?.value) {
        const optionElement = currentEditor.value.options.element

        if (!blockRef.value.contains(optionElement)) {
          return
        }
      }

      if (toolbar && !toolbar.contains(event.target)) {
        currentEditor.value = null
      }
    }
  })

  const setCurrentEditor = () => {
    if (block.editor) {
      currentEditor.value = block.editor
    }
  }

  const updateEditor = (editor) => {
    block.editor = editor
  }

  return {
    onUpdatedBlock,
    setCurrentEditor,
    updateEditor,
    blockRef
  }
}
