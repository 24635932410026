import { inject } from 'vue'

export function useSaveStatus() {
  const statusMessage = inject('saveStatusMessage')
  const isSaving = inject('isSaving')

  const setStatus = (message) => {
    statusMessage.value = message
  }

  const setIsSaving = (newState) => {
    isSaving.value = newState
  }

  const setStatusToLastSaved = () => {
    statusMessage.value = `Last saved at ${new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`
  }

  return {
    statusMessage,
    setStatus,
    setStatusToLastSaved,
    setIsSaving,
  }
}
