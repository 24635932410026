import { ref } from 'vue'

export const useYouTubePlayer = (block_id) => {
  const playerRef = ref(null)

  const initYouTubePlayer = (videoId, start_seconds = null, end_seconds = null) => {
    const playerElement = document.querySelector(`#player-${block_id} [data-youtube-target="video"]`)

    if (!playerRef.value) {
      setTimeout(() => {
        if (window.YT && YT.Player) {
          playerRef.value = new YT.Player(playerElement, {
            events: {
              onReady: (e) => {
                e.target.cueVideoById({
                  videoId: videoId,
                  startSeconds: start_seconds,
                  endSeconds: end_seconds
                })
              }
            }
          })
        }
      }, 1000)
    } else {
      playerRef.value.cueVideoById({
        videoId: videoId,
        startSeconds: start_seconds,
        endSeconds: end_seconds
      })
    }
  }

  return { playerRef, initYouTubePlayer }
}
